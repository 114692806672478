import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => (
    <SeoPage
        location={location}
        title="Conduite avec les facultés affaiblies : Quelles sont les conséquences de l’alcool au volant?"
        description="“ Évitez les conséquences de l’alcool au volant et défendez-vous avec l’aide d’un avocat spécialisé!">
        <p>
            <strong>
                Une soirée un peu trop arrosée s’est soldée par une arrestation
                pour conduite avec les facultés affaiblies par l’alcool?{' '}
            </strong>
        </p>
        <p>
            Une telle infraction s’avère lourde de conséquences. Elle peut se
            solder par la suspension du permis de conduire et par l’imposition
            d’un casier judiciaire, alors agissez rapidement auprès d’un avocat
            pour vous défendre.{' '}
        </p>
        <p>
            <Img
                fluid={data.img0.childImageSharp.fluid}
                title="Conséquences de l’alcool au volant"
                alt=""
            />
        </p>
        <p>
            <Link to="/">Avocatsalcoolauvolant.ca</Link> dispose d’un réseau
            d’avocats spécialisés en matière de conduite avec les facultés
            affaiblies et d’alcool au volant.{' '}
        </p>
        <p>
            On vous présente donc TOUTES les conséquences reliées à une telle
            accusation, et on vous réfère directement aux bons avocats pour vous
            représenter, sans aucun engagement{' '}
        </p>
        <p>
            <strong>
                Remplissez notre formulaire en quelques clics, et on s’occupe de
                trouver votre avocat!{' '}
            </strong>
        </p>
        <h2>
            Qu’est-ce qui constitue une infraction de conduite avec les facultés
            affaiblies?{' '}
        </h2>
        <p>
            <strong>
                L’infraction de conduite avec les facultés affaiblies est prévue
                dans le Code criminel canadien.{' '}
            </strong>
        </p>
        <p>
            Et<strong> </strong>contrairement à la croyance populaire, une telle
            infraction peut être commise même si le taux d’alcoolémie est sous
            la limite permise (0.08), et elle peut également être causée par
            l’effet de médicaments, de drogues ou d’une combinaison de
            substances.{' '}
        </p>
        <p>
            <strong>
                Voici la définition de l’infraction prévue dans le Code criminel
                Canadien!{' '}
            </strong>
        </p>
        <table>
            <tr>
                <td>
                    <p>
                        <strong>Capacité de conduire affaiblie</strong>
                    </p>
                    <p>
                        <strong>320.14</strong> <strong>(1)</strong> Commet une
                        infraction quiconque :
                    </p>
                    <p>
                        <strong>a)</strong> conduit un moyen de transport alors
                        que sa capacité de conduire est affaiblie à un
                        quelconque degré par l’effet de l’alcool ou d’une drogue
                        ou par l’effet combiné de l’alcool et d’une drogue;
                    </p>
                    <p>
                        <strong>b)</strong> sous réserve du paragraphe (5), a,
                        dans les deux heures suivant le moment où il a cessé de
                        conduire un moyen de transport, une alcoolémie égale ou
                        supérieure à{' '}
                        <strong>
                            quatre-vingts milligrammes d’alcool par cent
                            millilitres de sang
                        </strong>
                        .
                    </p>
                </td>
            </tr>
        </table>
        <p>
            <strong>
                Le taux d’alcool retrouvé dans le sang demeure l’élément central
                de cette infraction!{' '}
            </strong>
        </p>
        <p>
            Au Canada, le taux d’alcoolémie permis est de 80mg par 100ml de sang
            lorsqu’une personne prend le volant de son véhicule. Le simple fait
            de prouver que le conducteur avait un taux d’alcoolémie supérieur au
            fameux « 0.08 » est généralement suffisant pour obtenir sa
            condamnation.
        </p>
        <p>
            Depuis la légalisation du cannabis, la loi a également fixé des
            barèmes pour déterminer la quantité de THC prohibée dans le sang. Un
            conducteur ayant entre 2 et 5 nanogrammes de THC dans le sang dans
            les 2 heures suivant la conduite pourrait être reconnu coupable de
            conduite avec les facultés affaiblies, par exemple.{' '}
        </p>
        <p>
            <strong>
                Le Code de la sécurité routière prévoit lui-aussi des
                conséquences relatives à l’alcool au volant!{' '}
            </strong>
            En effet, le Code criminel et le Code de la sécurité routière sont
            les deux lois qui encadrent l’infraction d’alcool au volant et qui
            en prévoient les conséquences.
        </p>
        <p>
            <strong>
                Voyez juste ici les différentes conséquences envisageables de
                l’alcool au volant!{' '}
            </strong>
        </p>
        <h2>Les conséquences immédiates au moment de l’arrestation </h2>
        <p>
            L’infraction d’alcool au volant est particulière, en ce sens qu’elle
            produit des conséquences immédiates, avant même qu’un verdict de
            culpabilité criminel n’ait été rendu. Ce sont des sanctions
            administratives qui sont imposées par la SAAQ dès qu’un conducteur
            est arrêté par un policier pour cause d’alcool au volant.{' '}
        </p>
        <p>
            <Img
                fluid={data.img1.childImageSharp.fluid}
                title="Conséquences de l’alcool au volant”"
                alt="Une image contenant personne, extérieur"
            />
        </p>
        <p>
            <strong>
                Voici les conséquences qui suivent dès l’arrestation pour
                conduite avec les facultés affaiblies!
            </strong>{' '}
        </p>
        <h3>La saisie du véhicule </h3>
        <p>
            Un conducteur qui se fait arrêter pour une première infraction
            d’alcool au volant ne se fera pas saisir son véhicule si son taux
            d’alcoolémie est en dessous du double de la limite permise (Moins de
            160mg/ml de sang).{' '}
        </p>
        <p>
            Dans le cas d’une première infraction, le véhicule sera toutefois
            saisi pour 30 jours saisi si le taux d’alcoolémie du conducteur
            dépasse le double de la limite permise. Et dans le cas d’une
            récidive, le véhicule sera plutôt saisi pour 90 jours, les frais de
            remorquage et d’entreposage étant à la charge du conducteur.{' '}
        </p>
        <h3>La suspension du permis de conduire </h3>
        <p>
            Dès l’arrestation du conducteur qui dépasse la limite légale, le
            permis de ce dernier est suspendu pour un période de 90 jours. Cette
            suspension est ferme, et il est impossible d’obtenir un permis
            restreint ou un antidémarreur éthylométrique durant cette période.{' '}
        </p>
        <p>
            Il s’agit d’une première suspension administrative du permis, mais
            une interdiction de conduire pourra être ajoutée à cette conséquence
            si le conducteur est reconnu coupable.{' '}
        </p>
        <h3>Programme d’évaluation du risque (SAAQ) </h3>
        <p>
            Certains conducteurs peuvent être contraints de se soumettre au
            Programme d’évaluation du risque de la SAAQ. Ce programme vise à
            évaluer le risque de récidive du conducteur et à évaluer ses
            habitudes de consommation ainsi que de conduite.{' '}
        </p>
        <p>
            La réussite de cette évaluation peut être requise pour certains
            conducteurs qui souhaitent récupérer son permis à l’issue du 90
            jours de suspension. Généralement, ce sont les récidivistes et les
            conducteurs dont le taux d’alcoolémie dépassait le double de la
            limite qui seront contraints de se soumettre à une telle évaluation.{' '}
        </p>
        <p>
            <strong>
                Cette évaluation vient avec des frais, et peut retarder
                l’obtention d’un nouveau permis de conduire auprès de la SAAQ!{' '}
            </strong>
        </p>
        <h2>
            Les conséquences dans le cas d’un verdict de culpabilité criminel
        </h2>
        <p>
            Il y a les sanctions administratives qui s’appliquent dès
            l’arrestation, mais il y a également les conséquences criminelles
            qui entrent en jeu une fois le verdict de culpabilité rendu. À quoi
            faut-il s’attendre si un juge vous reconnait coupable de conduite
            avec les facultés affaiblies?{' '}
        </p>
        <p>
            <Img
                fluid={data.img2.childImageSharp.fluid}
                title="Conséquences criminelles facultés affaiblies"
                alt="Une image contenant table, tasse, intérieur, boisson"
            />
        </p>
        <p>
            <strong>
                Voici les conséquences prévues par le Code criminel en fonction
                du nombre
            </strong>{' '}
            <strong>d’infraction au dossier du conducteur!</strong>{' '}
        </p>
        <h3>
            1<sup>ère</sup> infraction d’alcool au volant{' '}
        </h3>
        <ul>
            <li>Casier judiciaire pour le conducteur </li>
            <li>
                Amende minimale de 1000$ (Entre 80mg et 159mg d’alcool / 100ml
                de sang)
            </li>
            <li>
                Amende minimale entre 1500$ et 2000$ (160mg d’alcool et plus /
                100ml de sang)
            </li>
            <li>Interdiction de conduire entre 1 et 3 ans </li>
            <li>Possibilité d’antidémarreur éthylométrique </li>
        </ul>
        <h3>
            2<sup>ième</sup> infraction d’alcool au volant{' '}
        </h3>
        <ul>
            <li>Emprisonnement minimal de 30 jours </li>
            <li>Interdiction de conduire allant jusqu’à 10 ans </li>
            <li>Possibilité d’un antidémarreur éthylométrique</li>
        </ul>
        <h3>
            3<sup>ième</sup> infraction d’alcool au volant{' '}
        </h3>
        <ul>
            <li>Emprisonnement minimal de 120 jours </li>
            <li>Interdiction de conduire minimale de 3 ans </li>
            <li>Interdiction absolue de conduire de 6 mois</li>
            <li>
                Possibilité d’un antidémarreur éthylométrique (après
                l’interdiction ferme de 6 mois).
            </li>
        </ul>
        <h3>L’interdiction de conduire criminelle </h3>
        <p>
            Le Code criminel prévoir une interdiction de conduire de 1 an pour
            toute personne reconnue coupable de conduire avec les facultés
            affaiblies. La récidive peut même faire passer cette suspension à
            une interdiction de conduire à vie!{' '}
        </p>
        <p>
            Il faut toutefois distinguer l’interdiction de conduire de
            l’interdiction <strong>absolue</strong>. Lors d’une interdiction de
            conduire « simple », le conducteur a la possibilité de demander un
            permis restreint en faisant installer un antidémarreur
            éthylométrique, ce qui lui permet d’utiliser son véhicule en toute
            légalité.{' '}
        </p>
        <p>
            L’obtention d’un tel permis restreint n’est toutefois pas possible
            lorsque l’interdiction de conduire est absolue. Pendant cette
            période, le conducteur ne peut utiliser son véhicule sous aucun
            prétexte.{' '}
        </p>
        <h2>
            Les frais à prévoir lors d’une infraction de conduite avec facultés
            affaiblies
        </h2>
        <p>
            Votre dossier de conduite et votre casier judiciaire souffriront
            tous les deux de votre infraction d’alcool au volant. Toutefois,
            sachez que votre portefeuille souffrira tout autant de cette
            situation, car le fait d’être accusé d’une telle infraction vient
            avec des frais importants.
        </p>
        <p>
            <Img
                fluid={data.img3.childImageSharp.fluid}
                title="Les frais reliés à l’alcool au volant"
                alt="Une image contenant texte, personne"
            />
        </p>
        <p>
            <strong>
                L’alcool au volant vient donc avec des conséquences criminelles,
                mais financières également – voici un aperçu des frais
                impliqués!{' '}
            </strong>
        </p>
        <table>
            <tr>
                <td>
                    <p>Remorquage de l’automobile </p>
                </td>
                <td>
                    <p>125$ à 170$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Remisage du véhicule </p>
                </td>
                <td>
                    <p>25$ / jour </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Évaluation des risques de la SAAQ </p>
                </td>
                <td>
                    <p>Sommaire : 300$ </p>
                    <p>Complète : 710$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        Contravention 1<sup>ère</sup> infraction{' '}
                    </p>
                    <p>(Amende minimale) </p>
                </td>
                <td>
                    <p>1000$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Contravention </p>
                    <p>(Taux d’alcoolémie au double de la limite)</p>
                </td>
                <td>
                    <p>1500$ à 2000$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Contravention </p>
                    <p>(Refus de souffler ou d’obtempérer)</p>
                </td>
                <td>
                    <p>2000$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Programme Alcofrein </p>
                </td>
                <td>
                    <p>150$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Antidémarreur Éthylométrique</p>
                </td>
                <td>
                    <p>Installation : 50$ </p>
                    <p>Frais mensuels : 61$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Obtention d’un nouveau permis de conduire (SAAQ) </p>
                </td>
                <td>
                    <p>363$ à 482$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Honoraires d’avocat </p>
                </td>
                <td>
                    <p>Environ 1000$ </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Total des frais </p>
                </td>
                <td>
                    <p>Entre 5500$ et 6100$ </p>
                </td>
            </tr>
        </table>
        <p>
            <strong>
                Plusieurs facteurs peuvent faire grimper les coûts reliés à
                l’alcool au volant!{' '}
            </strong>
        </p>
        <p>
            La récidive, les facteurs aggravants, le nombre d’heures consacrées
            au dossier par votre avocat et le fait que le véhicule soit saisi ou
            non auront tous un impact direct sur le montant total des frais
            reliés au règlement de votre dossier.{' '}
        </p>
        <h2>
            FAQ – Questions fréquentes sur les conséquences de l’alcool au
            volant!{' '}
        </h2>
        <p>
            <strong>Qu’est-ce qu’un antidémarreur éthylométrique? </strong>
        </p>
        <p>
            L’antidémarreur éthylométrique est installé dans le véhicule et
            oblige le conducteur à souffler dans l’appareil afin de faire
            démarrer le moteur. Comme cet appareil détecte le taux d’alcoolémie,
            le conducteur ne doit avoir aucune trace d’alcool dans son système
            pour démarrer le véhicule
        </p>
        <p>
            <Img
                fluid={data.img4.childImageSharp.fluid}
                title="Questions fréquentes alcool au volant"
                alt=""
            />
        </p>
        <h3>À qui s’adresse l’antidémarreur éthylométrique au Québec? </h3>
        <p>
            L’antidémarreur éthylométrique s’adresse aux conducteurs qui font
            l’objet d’une interdiction de conduire, mais qui souhaitent
            bénéficier d’un permis restreint afin de récupérer le droit de
            conduire. La pose d’un antidémarreur est la condition imposée pour
            octroyer le permis restreint.{' '}
        </p>
        <h3>
            Quel est l’impact de l’alcool au volant sur votre assurance
            automobile?{' '}
        </h3>
        <p>
            Le fait d’être condamné pour alcool au volant a pour effet de faire
            augmenter les primes. Un assureur peut également refuser de vous
            couvrir s’il juge que votre niveau de risque est trop élevé.{' '}
        </p>
        <h3>
            Est-il possible d’obtenir une absolution pour une infraction
            d’alcool au volant?{' '}
        </h3>
        <p>
            L’infraction de conduite avec les facultés affaiblies est assortie
            d’une peine minimale. Il n’est donc pas possible d’obtenir une
            absolution pour cette infraction. Seul l’acquittement peut vous
            permettre d’éviter une sanction légale et un casier judiciaire.{' '}
        </p>
        <h3>
            Pourrez-vous obtenir un permis restreint pendant la période de
            suspension de 90 jours imposée par la SAAQ?{' '}
        </h3>
        <p>
            Non, l’obtention d’un permis restreint n’est pas possible pendant la
            période de suspension de 90 jours, puisqu’il s’agit d’une suspension
            absolue. Un conducteur pourra demander un permis restreint après
            cette période, à moins qu’il ne fasse l’objet d’une interdiction
            absolue de plus longue imposée par le tribunal.{' '}
        </p>
        <h2>
            Trouvez un avocat pour vous défendre contre une accusation d’alcool
            au volant!
        </h2>
        <p>
            <strong>
                Une amende salée, la suspension du permis et le casier
                judiciaire… voilà quelques-unes des conséquences néfastes d’une
                infraction d’alcool au volant.{' '}
            </strong>
        </p>
        <p>
            La bonne nouvelle, c’est qu’il existe des moyens de défense pour
            repousser une accusation de conduite avec les facultés affaiblies,
            et il suffit de trouver un avocat qualifié pour les mettre en œuvre.{' '}
        </p>
        <p>
            Avocatsalcoolauvolant.ca est LA ressource dont vous avez besoin pour
            vous défendre.{' '}
        </p>
        <p>
            <strong>
                En remplissant notre formulaire, nous assurons une mise en
                contact rapide, gratuite et sans aucun engagement avec un avocat
                près de chez vous.{' '}
            </strong>
        </p>
        <p>
            <strong>
                Qu’attendez-vous pour trouver un professionnel qui vous défendra
                face à votre accusation d’alcool au volant?{' '}
            </strong>
        </p>
    </SeoPage>
)

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "consequences-alcool-volant/consequences-alcool-volant-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "consequences-alcool-volant/consequences-immediates-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "consequences-alcool-volant/amende-emprisonnement-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "consequences-alcool-volant/frais-payer-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "consequences-alcool-volant/questions-frequentes-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
